/* eslint-disable no-fallthrough */
import {
  ErrorCodeMap,
  ErrorMessageMap,
  ErrorTypeMap,
} from 'components/api/error-map';
import {
  redirectToIoaLogin,
  redirectToLogoutApi,
  redirectToMessagePage,
  redirectToMobileLoginPage,
  redirectToWorkWxLogin,
  redirectToLoginPage,
  redirectToBindPhoneNumber,
} from './redirect';
import { runPipe } from './pipe';
const generalRedirectList = [
  redirectToWorkWxLogin,
  redirectToLoginPage,
  redirectToMobileLoginPage,
];

const redirectDispatchMap = {
  [ErrorMessageMap.NOT_FIND_PRODUCTS]: [
    () => redirectToMessagePage(ErrorTypeMap.NOT_FIND),
  ],
  [ErrorMessageMap.NEED_LOGIN]: generalRedirectList,
  [ErrorMessageMap.NOT_LOGIN]: generalRedirectList,
  [ErrorCodeMap.TRANSITION_OA_AUTH]: [redirectToIoaLogin],
  [ErrorCodeMap.TRANSITION_PUBLIC_AUTH]: [redirectToLogoutApi],
  [ErrorCodeMap.NEED_BIND_PHONE_NUMBER]: [redirectToBindPhoneNumber],
};

/**
 * 前端拦截请求返回错误，根据不同的错误状态执行不同的重定向操作
 * @param status 状态码
 * @param message 服务器message
 */
export function checkResponseRedirect(
  status: ErrorCodeMap,
  message: ErrorMessageMap,
) {
  // 优先根据错误code重定向
  if (redirectDispatchMap[status]) {
    runPipe(...redirectDispatchMap[status]);
  }

  // 根据错误信息重定向
  if (redirectDispatchMap[message]) {
    runPipe(...redirectDispatchMap[message]);
  }
}

export * from './redirect';
