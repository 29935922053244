import { getApiSystemLogin, postApiV2Logout } from 'components/api';
import { ErrorTypeMap } from 'components/api/error-map';
import { getQQConnectURL } from 'components/user';
import { workWzLogin, wxLogin } from '../login';
import { pageInfo } from '../page-info';
import { exitPipe } from './pipe';
import { filenameIncludes } from './utils';
import Route from '../route';
import { hrefToLoginError, hrefToForbidden } from 'components/href-helper';
import { isPc } from 'components/env';

// 重定向到错误展示页
export function redirectToMessagePage(type: ErrorTypeMap) {
  if (filenameIncludes(['embed__message', 'products__message'])) {
    return;
  }

  Route.push(`${pageInfo.prefixPath}/message?type=${type}`);
  exitPipe();
}

// 跳转到绑定手机号页面
export function redirectToBindPhoneNumber() {
  const isProduct = window.location.pathname.startsWith('/product');
  const baseUrl = '/setting/phone';
  const search = isProduct ? `?from=${pageInfo.productId}` : '';
  Route.push(baseUrl + search);
  exitPipe();
}

// 重定向到黑名单展示页
export function redirectToBlacklistPage() {
  // 排除黑名单展示页
  if (filenameIncludes(['embed__blacklist', 'products__blacklist'])) {
    return;
  }

  Route.push(`${pageInfo.prefixPath}/blacklist`);
  exitPipe();
}

// 企业微信产品需要走特殊登陆逻辑
export function redirectToWorkWxLogin() {
  if (Number(pageInfo.productId) !== 4478) {
    return;
  }

  workWzLogin();
  exitPipe();
}

// ioa静默登录
export function redirectToIoaLogin() {
  // 排除黑名单展示页，消息提示页
  if (
    filenameIncludes([
      'embed__blacklist',
      'products__message',
      'embed__wxwork-tip',
    ])
  ) {
    return;
  }

  // 开发环境下不跳转。
  if (process.env.NODE_ENV === 'development') {
    return;
  }
  // 微信/企业微信/Moa webView/KM webView/PC端，ioa支持静默登录
  if (
    pageInfo.inWechat
    || pageInfo.inWorkWechatMobile
    || pageInfo.inKm
    || pageInfo.inMoa
    || !pageInfo.inMobile
    || pageInfo.inMeeting
  ) {
    Route.push(`${pageInfo.host}/login/ioa?tucao_redirect_uri=${encodeURIComponent(location.pathname.substr(1) + location.search)}`);
  } else {
    // 其他场景提示请在微信中打开
    Route.push(`${pageInfo.prefixPath}/wxwork-tip`);
  }

  exitPipe();
}

// 登出
export function redirectToLogoutApi() {
  postApiV2Logout().then(() => {
    if (isPc) {
      Route.push(hrefToLoginError());
      return;
    }
    // 小程序是否需要特殊处理
    window.location.reload();
  });

  exitPipe();
}

// 微信静默登录
export function redirectToWxLogin() {
  // 非微信或非小程序状态不登录
  if (!pageInfo.inWechat && !pageInfo.inMp) {
    return;
  }

  wxLogin();
  exitPipe();
}

// qq登录
export function redirectToQqLogin() {
  // 非qq环境不使用qq登录
  if (!pageInfo.inMobileQQ) {
    return;
  }

  Route.push(getQQConnectURL());
  exitPipe();
}

// 游客态登录
export function redirectToSystemLogin() {
  // 如果当前页面是个人中心页，不登录
  if (filenameIncludes(['embed__profile'])) {
    return;
  }

  // 如果是PC端、手机QQ、手机微信、MOA、小程序打开，不登录游客态
  if (
    !(pageInfo.inMobile || pageInfo.inIpad)
    || pageInfo.inMobileQQ
    || pageInfo.inWechat
    || pageInfo.inMoa
    || pageInfo.inMp
  ) {
    return;
  }

  getApiSystemLogin(pageInfo.productId)
    .then(() => {
      // iframe sameset 可能会存在跨域无法登陆的问题造成无限reload
      window.top === window && location.reload();
    })
    .catch((err) => {
      const touristErr = '该产品禁止游客态登录';
      if (err.message === touristErr) {
        Route.push(hrefToForbidden());
      }
      return Promise.reject(err);
    });
  exitPipe();
}

// 重定向到登陆页
export function redirectToLoginPage() {
  // 只有管理后台需要重定向到登陆页
  if (!pageInfo.isDashboard) {
    return;
  }

  Route.push('/login.html');
  exitPipe();
}


export function redirectToMobileLoginPage() {
  // 只有管理后台需要重定向到登陆页
  if (!window.location.pathname.startsWith('/embed')) {
    return;
  }
  Route.push(`/embed/phone/${pageInfo.productId}/login?redirect_path=${encodeURIComponent(window.location.pathname + window.location.search)}`);
  exitPipe();
}
