import { getEnv, ALL_ENV } from 'components/env';
import xss from 'xss';

/**
 * 临时解决微信webview授权登录后localstorage丢失问题
 */
export const getSearch = () => {
  let { search } = location;
  const oldFontRatio = localStorage.getItem('fontRatio');
  if (oldFontRatio) {
    search = search ? `${search}&font_ratio=${oldFontRatio}` : `?font_ratio=${oldFontRatio}`;
  }
  return search;
};
/**
 * 微信中主动获取用户授权
 * 重定向到指定路由拼接参数后跳转到微信指定重定向页面
 */
export const wxLogin = () => {
  const search = getSearch();
  // pathname获取到的url路径包含一个初始的'/'，后端已经拼上，这里前端删掉首个/
  const redirectUri = encodeURIComponent(`${location.pathname.slice(1)}${xss(search)}`);
  window.location.href = `/login/mobile/weixin?tucao_redirect_uri=${redirectUri}`;
};

export const workWzLogin = () => {
  const search = getSearch();
  // pathname获取到的url路径包含一个初始的'/'，后端已经拼上，这里前端删掉首个/
  const redirectUri = encodeURIComponent(`${location.pathname.slice(1)}${xss(search)}`);
  window.location.href = `/login/work/weixin?tucao_redirect_uri=${redirectUri}`;
};

/**
 * 登录模块
 * 在不同环境执行不同登录方式
 */
export default () => {
  const env = getEnv();
  // 微信登录
  if (env === ALL_ENV.wechat || env === ALL_ENV.miniprogramWeb) {
    wxLogin();
    return;
  }
};
