import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Tag from 'components/tag';
import { weakenBackground } from 'components/dark-mode';

import './style.less';

/**
 * React Effect 中的阻止滚动
 */
const effectPreventTouchMove = () => {
  const doc = document.documentElement;
  const { scrollTop } = doc;

  // 将 HTML 元素设置为 fixed ，避免被滚动
  doc.style.position = 'fixed';
  // 将滚动高度赋值给 HTML 的高度，避免视窗内的区域发送变化
  doc.style.top = `-${scrollTop}px`;
  doc.style.overflow = 'hidden';
  doc.scrollTop = 0;

  return () => {
    // 还原
    doc.style.position = '';
    doc.style.top = '';
    doc.style.overflow = '';
    doc.scrollTop = scrollTop;
  };
};

/**
 * 遮罩
 * @param {boolean} [props.weakenBack]  黑暗模式，是否启用黑色模糊蒙版
 * @param {function} props.onClick      点击的回调函数
 * @param {boolean} [props.dark]        黑暗模式，黑色背景且只有10%透明度
 */
const Mask = (props) => {
  useEffect(() => {
    const root = document.getElementById('root-container');
    if (root) {
      root.ariaHidden = true;
      // 深色模式下对 #root-container 处理
      if (!props.weakenBack) weakenBackground(true);
    }
    return () => {
      if (root) {
        root.ariaHidden = false;
        weakenBackground(false);
      }
    };
  });

  return (
    <Tag.div className={classNames('mask', { dark: props.dark }, props.className)} onClick={props.onClick}>{props.children}</Tag.div>
  );
};
Mask.propTypes = {
  dark: PropTypes.bool,
  weakenBack: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};
export { Mask, effectPreventTouchMove };
