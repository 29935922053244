/**
 * 判断当前是否暗黑模式
 */
export const isDarkMode = Boolean(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);

/**
 * 暗黑模式时候， 设置主区域透明度
 * @param isActive
 */
export function weakenBackground(isActive) {
  // 非暗黑模式，不启动
  if (!isDarkMode) return;

  const rootContainer = document.getElementById('root-container');

  if (!rootContainer) return;

  // 如果输入框是激活态，设置主区域半透明
  if (isActive) {
    rootContainer.classList.add('weaken-background');
  } else {
    rootContainer.classList.remove('weaken-background');
  }
}
